<template>
    <v-autocomplete
        :items="branches"
        item-text="name"
        item-value="id"
        :value="value"
        :label="label || $t('attributes.branch')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :chips="chips"
        :return-object="false"
    ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "BranchesField",

    props: {
        value: [String, Number],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        chips: { type: Boolean, default: false },
        bank: [Object, Number]
    },

    computed: {
        ...mapState({
            data: state => state.branch.items.data
        }),

        branches() {
            let bank_id = (this.bank && this.bank.id) || this.bank;
            if (!bank_id) return [];
            return this.data && this.data.filter(i => i.bank_id == bank_id);
        }
    },

    created() {
        if (!this.data || !this.data.data) this.$store.dispatch("branch/fetchAll");
    }
};
</script>
