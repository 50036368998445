<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('settlements.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">
                        {{ settlement.id }}
                    </div>

                    <div>
                        <!-- btn edit item  -->
                        <!-- <v-btn v-if="canEditInvoice" fab small dark :color="$store.state.app.color" :to="`/orders/update/${settlement.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn> -->

                        <!-- brn print item -->
                        <!-- <v-btn
                            fab
                            small
                            color="blue-grey darken-3"
                            dark
                            class="ms-1"
                            :href="serverUrl(`/orders/${settlement.id}?key=${$store.state.auth.key}`)"
                            target="_blank"
                        >
                            <v-icon>mdi-printer</v-icon>
                        </v-btn> -->
                    </div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <div class="pa-5">
                    <!-- Start settlement information -->
                    <v-row>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.count" :label="$t('attributes.count')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.amount" :label="$t('attributes.amount')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.fee_percentage" :label="$t('attributes.fee_percentage')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.fee" :label="$t('attributes.fee')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.net_amount" :label="$t('attributes.net_amount')" readonly></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="settlement.created_at" :label="$t('attributes.created_at')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model.trim="settlement.note" :label="$t('attributes.note')" counter rows="1" auto-grow readonly />
                        </v-col>
                    </v-row>
                    <!-- End settlement information -->

                    <v-divider></v-divider>
                    <v-data-table :headers="headers" :items="settlement.orders" :items-per-page="10000000" hide-default-footer class="elevation-1" />
                </div>

                <v-divider></v-divider>
            </template>
        </base-card>
    </div>
</template>

<script>
import store from "@/store/index";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;

        store.dispatch("settlement/fetchById", id).then(() => {
            next();
        });
    },

    data() {
        return {
            headers: [
                { text: this.$t("attributes.card_type"), value: "card_type" },
                { text: this.$t("attributes.count"), value: "count" },
                { text: this.$t("attributes.amount"), value: "amount" }
            ]
        };
    },

    computed: {
        settlement() {
            return this.$store.state.settlement.item.data;
        }
    }
};
</script>
