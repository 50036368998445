import BaseStoreModule from "@/plugins/utils/baseStoreModule";
import BaseStoreAdvancedModule from "@/plugins/utils/baseStoreAdvancedModule";
import BaseServiceApi from "@/plugins/utils/baseServiceApi";

export default {
    /**
     * To save all services that created in this object
     */
    services: {},

    /**
     * Generate Store Module
     * @param {string} end_point end point of api
     * @param {boolean} namespaced module namespaced
     *
     * @returns
     */
    generateStoreModule(end_point, namespaced) {
        let s = new BaseServiceApi(end_point);
        this.services[end_point] = s;
        return new BaseStoreModule(s, namespaced);
    },

    /**
     * Generate Store Module
     * @param {string} end_point end point of api
     * @param {boolean} namespaced module namespaced
     *
     * @returns
     */
    generateStoreAdvancedModule(end_point, namespaced) {
        let s = new BaseServiceApi(end_point);
        this.services[end_point] = s;
        return new BaseStoreAdvancedModule(s, namespaced);
    }
};
