var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-crud',{attrs:{"icon":_vm.$t('card_types.icon'),"title":_vm.$t('card_types.title'),"stateModule":"cardType","enable-add-option":true,"enable-update-option":true,"headers":_vm.headers,"add-edit-form-width":912,"default-valus-for-add":_vm.defaultValusForAdd},scopedSlots:_vm._u([{key:"addEditForm",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.min_count')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.min_count'),"error-messages":errors,"type":"number","counter":"","maxlength":10},model:{value:(item.min_count),callback:function ($$v) {_vm.$set(item, "min_count", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.min_count"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.sell_price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.sell_price'),"error-messages":errors,"type":"number","counter":"","maxlength":10},model:{value:(item.sell_price),callback:function ($$v) {_vm.$set(item, "sell_price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.sell_price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('attributes.state')},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:1000","name":_vm.$t('attributes.note')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('attributes.note'),"error-messages":errors,"counter":"","maxlength":150,"rows":"2","auto-grow":"","clearable":""},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.note"}})]}}],null,true)})],1)],1)]}},{key:"searchForm",fn:function(){return [_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"clearable":""},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('attributes.note'),"clearable":""},model:{value:(_vm.search.note),callback:function ($$v) {_vm.$set(_vm.search, "note", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.note"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('statuses-field',{attrs:{"label":_vm.$t('attributes.state'),"clearable":""},model:{value:(_vm.search.state),callback:function ($$v) {_vm.$set(_vm.search, "state", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"search.state"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }