import axios from "axios";
import store from "@/store/index";
import i18n from "@/plugins/i18n";
import moment from "moment";
import AuthApi from "@/apis/authApi";

window.axios = axios;

if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "http://127.0.0.1:8000/api/client";
    store.state.app.serverUrl = "http://127.0.0.1:8000";
} else {
    axios.defaults.baseURL = "/api/client";
    store.state.app.serverUrl = location.origin;
}

let _last_request = moment();

// Add a response interceptor
axios.interceptors.response.use(
    function(res) {
        _last_request = moment();
        return Promise.resolve(res);
    },
    function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (!error.response) {
            Toast.error(i18n.t("errors.check_your_network"));
            store.state.app.loading = false;
            return Promise.reject(error);
        }

        if (error.response.status === 401) {
            // that means need to login
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return Promise.reject(error);
        }

        let http_errors = {
            "400": "errors.something_is_wrong",
            "403": "errors.permission_denied",
            "404": "errors.no_result_found",
            "500": "errors.something_is_wrong"
        };

        if (error.response.status in http_errors) {
            Toast.error(i18n.t(`${http_errors[error.response.status] || "errors.something_is_wrong"}`));
            store.state.app.loading = false;
        }

        return Promise.reject(error);
    }
);

setInterval(function() {
    if (store.getters["auth/isLogged"] && moment.duration(moment().diff(_last_request)).asMinutes() > 1) {
        AuthApi.profile();
    }
}, 60000);
