<template>
    <v-select
        :items="statuses"
        item-value="id"
        item-text="name"
        :value="value"
        :label="label || $t('attributes.state')"
        @input="$emit('input', $event)"
        clearable
        :outlined="outlined"
        :error-messages="errorMessages"
    />
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "StatusesField",

    props: {
        value: [String, Number, Boolean],
        label: { type: String },
        outlined: {
            type: Boolean,
            default: false
        },
        errorMessages: [String, Array]
    },

    data() {
        return {
            statuses: [
                { id: true, name: "فعال" },
                { id: false, name: "غير فعال" }
            ]
        };
    }
};
</script>
