<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('orders.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">
                        {{ order.id }}
                    </div>

                    <div>
                        <!-- btn edit item  -->
                        <!-- <v-btn v-if="canEditInvoice" fab small dark :color="$store.state.app.color" :to="`/orders/update/${order.id}`">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn> -->

                        <!-- brn print item -->
                        <!-- <v-btn
                            fab
                            small
                            color="blue-grey darken-3"
                            dark
                            class="ms-1"
                            :href="serverUrl(`/orders/${order.id}?key=${$store.state.auth.key}`)"
                            target="_blank"
                        >
                            <v-icon>mdi-printer</v-icon>
                        </v-btn> -->
                    </div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>

                <div class="pa-5">
                    <!-- Start order information -->
                    <v-row>
                        <v-col cols="12">
                            <h3>{{ $t("attributes.order_information") }}</h3>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="order.status.name" :label="$t('attributes.status')" readonly></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="order.created_at" :label="$t('attributes.created_at')" readonly></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field v-model.trim="order.bot_sent_at" :label="$t('attributes.sent_at')" readonly></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- End order information -->

                    <v-divider></v-divider>

                    <!-- Start card information -->
                    <v-row>
                        <v-col cols="12">
                            <h3>{{ $t("attributes.card_information") }}</h3>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.card_type.name" :label="$t('attributes.card_type')" readonly></v-text-field>
                        </v-col>
                        <template v-if="order.card">
                            <!-- <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="order.card.buy_price" :label="$t('attributes.buy_price')" readonly></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="order.card.imported_card_id" :label="$t('attributes.imported_id')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="order.card.created_at" :label="$t('attributes.created_at')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="order.card.serial_number" :label="$t('attributes.serial_number')" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <v-text-field :value="order.card.card_code" :label="$t('attributes.code')" readonly></v-text-field>
                            </v-col>
                        </template>
                    </v-row>
                    <!-- End card information -->

                    <!-- Start payment information -->
                    <v-row v-if="order.payment">
                        <v-col cols="12">
                            <h3>{{ $t("attributes.payment_information") }}</h3>
                        </v-col>

                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.amount" :label="$t('attributes.amount')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.fee" :label="$t('attributes.fee')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.total_amount" :label="$t('attributes.total_amount')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.payment_method" :label="$t('attributes.payment_method')" readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.customer_phone" :label="$t('attributes.phone')" readonly></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" lg="3">
                            <v-text-field :value="order.payment.email" :label="$t('attributes.email')" readonly></v-text-field>
                        </v-col>  -->
                    </v-row>
                    <!-- End payment information -->
                </div>

                <v-divider></v-divider>
            </template>
        </base-card>
    </div>
</template>

<script>
import store from "@/store/index";

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        let id = routeTo.params.id;

        store.dispatch("order/fetchById", id).then(() => {
            next();
        });
    },

    computed: {
        order() {
            return this.$store.state.order.item;
        }
    }
};
</script>
