<template>
    <v-row>
        <v-col cols="12" md="8">
            <base-card class="my-4 mx-auto" :icon="$t('profile.icon')">
                <template #title>
                    <div class="d-flex justify-space-between">
                        <div class="headline">{{ $t("profile.title") }}</div>
                    </div>
                </template>

                <template #content>
                    <v-divider></v-divider>

                    <validation-observer ref="profileForm" v-slot="{ handleSubmit }">
                        <v-form class="px-5" @submit.prevent="handleSubmit(updateProfileOfUser)">
                            <!-- show errors if there is errors when add/update item -->
                            <has-errors :errors="errors"></has-errors>

                            <v-row class="pt-5">
                                <v-col cols="12">
                                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="user.name"
                                            :label="$t('attributes.name')"
                                            :error-messages="errors"
                                            counter
                                            outlined
                                            :maxlength="32"
                                            readonly
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <validation-provider rules="required" :name="$t('attributes.phone')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="user.phone"
                                            :label="$t('attributes.phone')"
                                            :error-messages="errors"
                                            counter
                                            outlined
                                            :maxlength="32"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <validation-provider rules="required|email" :name="$t('attributes.email')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="user.email"
                                            :label="$t('attributes.email')"
                                            :error-messages="errors"
                                            counter
                                            outlined
                                            :maxlength="64"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>

                                <!-- <v-col cols="12"  >
                                    <validation-provider rules="required|phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="user.phone"
                                            :label="$t('attributes.phone')"
                                            counter
                                            outlined
                                            :maxlength="10"
                                            :error-messages="errors"
                                            readonly
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col> -->
                                <!-- <v-col cols="12"  >
                                    <validation-provider rules="required|email" :name="$t('attributes.email')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="user.email"
                                            :label="$t('attributes.email')"
                                            counter
                                            type="email"
                                            outlined
                                            :error-messages="errors"
                                            readonly
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col> -->

                                <v-col cols="12">
                                    <v-text-field :value="user.created_at" :label="$t('attributes.created_at')" outlined readonly></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field :value="user.updated_at" :label="$t('attributes.updated_at')" outlined readonly></v-text-field>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <!-- <div class="text-end px-5 py-3">
                                <v-btn color="info" min-width="150px" type="submit">{{ $t("options.update") }}</v-btn>
                            </div> -->
                        </v-form>
                    </validation-observer>
                </template>
            </base-card>
        </v-col>
        <v-col cols="12" md="4">
            <base-card class="my-4 mx-auto" :icon="$t('auth.update_password.icon')">
                <template #title>
                    <div class="d-flex justify-space-between">
                        <div class="headline">{{ $t("auth.update_password.title") }}</div>
                    </div>
                </template>

                <template #content>
                    <v-divider></v-divider>

                    <validation-observer ref="changePasswordForm" v-slot="{ handleSubmit }">
                        <v-form class="px-5" @submit.prevent="handleSubmit(updatePasswordOfUser)">
                            <!-- show errors if there is errors when add/update item -->
                            <has-errors :errors="password_errors"></has-errors>

                            <v-row class="pt-5">
                                <v-col cols="12">
                                    <validation-provider rules="required|min:6|max:32" :name="$t('attributes.current_password')" v-slot="{ errors }">
                                        <password-field
                                            v-model.trim="passowrd.current_password"
                                            :label="$t('attributes.current_password')"
                                            :error-messages="errors"
                                            :max-length="32"
                                            outlined
                                            :show-icon="false"
                                        >
                                        </password-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12">
                                    <validation-provider rules="required|min:6|max:32" :name="$t('attributes.new_password')" v-slot="{ errors }">
                                        <password-field
                                            v-model.trim="passowrd.new_password"
                                            :label="$t('attributes.new_password')"
                                            :error-messages="errors"
                                            :max-length="32"
                                            outlined
                                            :show-icon="false"
                                        >
                                        </password-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>

                            <v-divider></v-divider>

                            <div class="text-end py-3">
                                <v-btn min-width="150px" type="submit">{{ $t("options.update") }}</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </template>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import CitiesField from "@/components/data/CitiesField.vue";

export default {
    //  components: { CitiesField },

    data: function() {
        return {
            user: {},
            passowrd: {},
            password_errors: {},
            errors: {}
        };
    },
    computed: {
        ...mapState({
            data: state => state.auth.user
        })
    },

    created() {
        this.user = Object.assign(this.data);
    },

    methods: {
        ...mapActions("auth", ["updateProfile", "updatePassword"]),

        /**
         * Add data to server
         */
        updateProfileOfUser() {
            this.errors = {};
            let user = Object.assign({}, this.user);
            //user.city_id = user.city.id;
            //delete user.city;

            this.$store.state.app.loading = true;
            this.updateProfile(user)
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Add data to server
         */
        updatePasswordOfUser() {
            this.$store.state.app.loading = true;
            this.password_errors = {};

            this.updatePassword(this.passowrd)
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.password_errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>
