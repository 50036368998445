<template>
    <v-autocomplete
        :items="items || []"
        item-text="name"
        item-value="id"
        :value="value"
        :label="label || $t('attributes.currency')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :chips="chips"
        :readonly="readonly"
        :return-object="false"
    ></v-autocomplete>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "CurrenciesField",

    props: {
        value: [String, Number],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        chips: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        exclude: [String, Number]
    },

    computed: {
        ...mapState({
            data: state => state.currency.items.data
        }),

        items() {
            if (this.exclude) return this.data && this.data.filter(i => i.id != this.exclude);
            return this.data;
        }
    },

    created() {
        if (!this.data || !this.data.data) this.$store.dispatch("currency/fetchAll");
    }
};
</script>
