import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import chat from "./modules/chat";
import auth from "@/store/modules/auth";
import statistic from "@/store/modules/statistic";
import helpers from "@/plugins/utils/helpers";
import setting from "@/store/modules/setting";
import permission from "@/store/modules/permission";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        app,
        chat,
        //product,
        setting,
        permission,
        exchangeRate: helpers.generateStoreModule("exchange-rates"),
        currency: helpers.generateStoreModule("currencies"),
        branch: helpers.generateStoreModule("branches"),
        //role: helpers.generateStoreModule("roles"),
        //user: helpers.generateStoreModule("users"),
        order: helpers.generateStoreAdvancedModule("orders"),
        settlement: helpers.generateStoreAdvancedModule("settlements"),
        orderStatus: helpers.generateStoreModule("order-statuses"),
        cardType: helpers.generateStoreModule("card-types"),
        importedCards: helpers.generateStoreAdvancedModule("imported-cards"),
        invoice: helpers.generateStoreAdvancedModule("invoices"),
        walletAccount: helpers.generateStoreModule("accounts"),
        walletTransaction: helpers.generateStoreAdvancedModule("transactions"),
        exchange: helpers.generateStoreAdvancedModule("exchanges"),
        transfer: helpers.generateStoreAdvancedModule("transfers"),
        sellOffer: helpers.generateStoreAdvancedModule("sell-offers"),
        auth,
        statistic
    }
});
