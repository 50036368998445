/**
 * This module for app settings
 */
export default {
    namespaced: true,

    state: {
        showListUsers: true
    }
};
