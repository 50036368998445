<template>
    <v-row>
        <v-col cols="12" md="8">
            <base-card class="my-4 mx-auto" :icon="$t('cards.inquire.icon')">
                <template #title>
                    <div class="d-flex justify-space-between">
                        <div class="headline">{{ $t("cards.inquire.title") }}</div>
                    </div>
                </template>

                <template #content>
                    <v-divider></v-divider>

                    <validation-observer ref="inquireForm" v-slot="{ handleSubmit }">
                        <v-form class="px-5" @submit.prevent="handleSubmit(inquierCard)">
                            <!-- show errors if there is errors when add/update item -->
                            <has-errors :errors="errors"></has-errors>

                            <v-row class="pt-5">
                                <v-col cols>
                                    <validation-provider rules="required|min:3|max:100" :name="$t('attributes.code')" v-slot="{ errors }">
                                        <v-text-field
                                            v-model.trim="search.code"
                                            :label="$t('attributes.code')"
                                            :error-messages="errors"
                                            counter
                                            outlined
                                            clearable
                                            :maxlength="100"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn :color="$store.state.app.color" x-large type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </validation-observer>

                    <v-divider></v-divider>

                    <v-row class="px-5">
                        <!-- <v-col cols="12" md="6">
                            <v-text-field :value="card.buy_price" :label="$t('attributes.buy_price')" outlined readonly></v-text-field>
                        </v-col> -->
                        <!-- <v-col cols="12" md="6">
                            <v-text-field
                                :value="card.sell_price || (card.card_type && card.card_type.sell_price)"
                                :label="$t('attributes.sell_price')"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-col> -->
                        <v-col cols="12" md="6">
                            <v-text-field
                                :value="card.card_type && card.card_type.name"
                                :label="$t('attributes.card_type')"
                                outlined
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" v-if="card.settlement_id">
                            <v-text-field :value="card.settlement_id" :label="$t('attributes.settlement_id')" outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field :value="card.imported_card_id" :label="$t('attributes.imported_id')" outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field :value="card.created_at" :label="$t('attributes.created_at')" outlined readonly></v-text-field>
                        </v-col>
                        <template v-if="card.sold_at">
                            <v-col cols="12" md="6">
                                <v-text-field :value="card.sold_at" :label="$t('attributes.sold_at')" outlined readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field :value="card.order.id" :label="$t('attributes.order_id')" outlined readonly></v-text-field>
                            </v-col>
                        </template>
                        <v-col cols="12" md="6" v-if="card.canceled_at">
                            <v-text-field :value="card.canceled_at" :label="$t('attributes.canceled_at')" outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="12" v-if="card.canceled_at">
                            <v-textarea
                                :value="card.canceled_reason"
                                :label="$t('attributes.canceled_reason')"
                                outlined
                                readonly
                                auto-grow
                                rows="2"
                            ></v-textarea>
                        </v-col>
                    </v-row>

                    <template v-if="card.id && !card.sold_at && !card.canceled_at">
                        <v-divider></v-divider>

                        <validation-observer ref="cancelForm" v-slot="{ handleSubmit }">
                            <v-form class="px-5" @submit.prevent="handleSubmit(cancelCard)">
                                <v-row class="pt-5">
                                    <v-col cols="12">
                                        <h2>{{ $t("attributes.cancel_card") }}</h2>
                                        <!-- show errors if there is errors when add/update item -->
                                        <has-errors :errors="errors"></has-errors>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider
                                            rules="required|min:3|max:200"
                                            :name="$t('attributes.canceled_reason')"
                                            v-slot="{ errors }"
                                        >
                                            <v-textarea
                                                v-model="cardToCancel.canceled_reason"
                                                :label="$t('attributes.canceled_reason')"
                                                outlined
                                                auto-grow
                                                clearable
                                                rows="2"
                                                counter
                                                :error-messages="errors"
                                                :maxlength="200"
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn color="error" large type="submit">{{ $t("options.cancel") }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </validation-observer>
                    </template>
                    <v-divider></v-divider>
                </template>
            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
//import CitiesField from "@/components/data/CitiesField.vue";

export default {
    //  components: { CitiesField },

    data: function() {
        return {
            search: {},
            card: {},
            cardToCancel: {},
            errors: {}
        };
    },

    methods: {
        /**
         * Add data to server
         */
        inquierCard() {
            this.errors = {};
            this.card = {};

            this.$store.state.app.loading = true;
            axios
                .get("cards/inquire", { params: this.search })
                .then(r => {
                    this.card = r.data;
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else if (e.response.status != 404) {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        cancelCard() {
            this.errors = {};
            this.cardToCancel.id = this.card.id;
            this.cardToCancel.card_type_id = this.card.card_type_id;

            this.$store.state.app.loading = true;
            axios
                .post("cards/cancel", this.cardToCancel)
                .then(r => {
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                    this.cardToCancel = {};
                    this.$refs.cancelForm.reset();
                    this.inquierCard();
                })
                .catch(e => {
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else if (e.response.status != 404) {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        }
    }
};
</script>
