<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('imported_cards.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("imported_cards.title") }}</div>
                </div>

                <!-- btn add new item  -->
                <v-btn fab small :color="$store.state.app.color" @click.stop="prepareToAddItem"><v-icon>mdi-plus</v-icon></v-btn>

                <!-- Start Add form dialog -->
                <dialog-base type="ADD" v-model="formDialog" @canceled="formDialogClose" @confirmed="validateAndSave" :max-width="450">
                    <template #content>
                        <validation-observer ref="baseCrudForm" v-slot="{ handleSubmit }">
                            <v-form class="px-3" @submit.prevent="handleSubmit(validateAndSave)">
                                <!-- show errors if there is errors when add/update item -->
                                <has-errors :errors="errors"></has-errors>

                                <v-row>
                                    <v-col cols="12">
                                        <validation-provider rules="size:20480|ext:xls,xlsx" :name="$t('attributes.file')" v-slot="{ errors }">
                                            <v-file-input
                                                v-model="item.file"
                                                :label="$t('attributes.file')"
                                                :error-messages="errors"
                                                show-size
                                                counter
                                            ></v-file-input>
                                        </validation-provider>
                                    </v-col>
                                    <v-col cols="12">
                                        <validation-provider rules="required" :name="$t('attributes.card_type')" v-slot="{ errors }">
                                            <card-types-field v-model.trim="item.card_type_id" :error-messages="errors" clearable></card-types-field>
                                        </validation-provider>
                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <validation-provider rules="required" :name="$t('attributes.buy_price')" v-slot="{ errors }">
                                            <v-text-field
                                                v-model.trim="item.buy_price"
                                                :label="$t('attributes.buy_price')"
                                                :error-messages="errors"
                                                type="number"
                                                counter
                                                :maxlength="10"
                                            ></v-text-field>
                                        </validation-provider>
                                    </v-col> -->
                                    <v-col cols="12">
                                        <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                                            <v-textarea
                                                v-model.trim="item.note"
                                                :label="$t('attributes.note')"
                                                :error-messages="errors"
                                                counter
                                                :maxlength="1000"
                                                rows="2"
                                                auto-grow
                                                clearable
                                            ></v-textarea>
                                        </validation-provider>
                                    </v-col>
                                </v-row>
                                <!-- This button to allow to user to submit form by clicking enter -->
                                <button type="submit" class="hide"></button>
                            </v-form>
                        </validation-observer>
                    </template>

                    <template #footer>
                        <!-- here will set forms extra options -->
                        <slot name="addEditFormFooter" :item="item" />
                    </template>
                </dialog-base>
                <!-- End Add form dialog -->
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selectedHeaders" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->
                <v-divider></v-divider>

                <!-- Start search panel -->
                <v-expansion-panels class="" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-start">
                            <h5 class="subtitle-1"><v-icon>mdi-magnify</v-icon> {{ $t("options.search") }}</h5>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <!-- Start search options -->
                            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <card-types-field v-model.trim="search.card_type_id" clearable />
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                            <component
                                                v-if="typeof field === 'object'"
                                                :is="field.component"
                                                v-model.trim="search[field.name]"
                                                :label="field.label"
                                                v-bind="field.props"
                                            />
                                            <v-text-field
                                                v-else
                                                v-model.trim="search[field]"
                                                :label="$t(`attributes.${field}`)"
                                                maxlength="32"
                                                clearable
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                            <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                            <!-- End search options -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table :headers="selectedHeaders" :items="data.data.data" :items-per-page="100" hide-default-footer class="elevation-1">
                    <!--  <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/transactions/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                        </div>
                    </template> -->
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data.data || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";
/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;

    store.dispatch("importedCards/fetchAll", { page, ...params }).then(() => {
        next();
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    data: function() {
        return {
            errors: {},
            item: {},
            formDialog: false,
            search: Object.assign({}, this.$route.query),
            searchFields: [
                "id",
                "note",
                { component: "date-field", name: "date_from", label: this.$t("search.date_from"), props: { clearable: true } },
                { component: "date-field", name: "date_to", label: this.$t("search.date_to"), props: { clearable: true } }
            ],
            headers: [
                { text: this.$t("attributes.id"), value: "id" },
                { text: this.$t("attributes.card_type"), value: "card_type.name" },
                //{ text: this.$t("attributes.buy_price"), value: "buy_price" },
                { text: this.$t("attributes.name"), value: "filename" },
                { text: this.$t("attributes.count"), value: "count" },
                { text: this.$t("attributes.size"), value: "size" },
                { text: this.$t("attributes.note"), value: "note" },
                { text: this.$t("attributes.created_at"), value: "created_at" }
                //{ text: this.$t("attributes.actions"), value: "actions" }
            ],
            selectedHeaders: []
        };
    },

    computed: {
        ...mapState({
            data: state => state.importedCards.items
        })
    },

    created() {
        this.selectedHeaders = this.headers.filter(
            i =>
                ![
                    /* "note",  "created_at"*/
                ].includes(i.value)
        );
    },

    methods: {
        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        },

        /**
         * Validate form if valid then call save method to send request to server
         */
        validateAndSave() {
            this.$refs.baseCrudForm.validate().then(success => {
                if (!success) return;
                this.save();
            });
        },

        /**
         * Add data to server
         */
        save() {
            this.$store.state.app.loading = true;
            this.errors = {};
            let data = Object.assign(this.item);

            data = this.convertObjectToForm(data, ["file"], []);

            this.$store
                .dispatch(`importedCards/add`, data)
                .then(r => {
                    this.formDialogClose();
                    Toast.success(this.$t("successes.operation_completed_successfully"));
                })
                .catch(e => {
                    window.eeee = e;
                    console.log(e);
                    if (e.response && e.response.data.errors) {
                        this.errors = e.response.data.errors;
                    } else {
                        Toast.error(this.$t("errors.something_is_wrong"));
                    }
                })
                .finally(() => (this.$store.state.app.loading = false));
        },

        /**
         * Close form dialog which responsible on add/delete data
         */
        formDialogClose() {
            this.formDialog = false;
            this.errors = {};
            this.$refs.baseCrudForm.reset();
            this.item = {};
        },

        /**
         *  Set configuration for Add item
         */
        prepareToAddItem() {
            this.item = {};
            this.formDialog = true;
        },

        convertObjectToForm(data, files, booleans) {
            let form = new FormData();
            for (let e in data) {
                if (typeof data[e] === "object") {
                    for (let t in data[e]) {
                        form.set(`${e}[${t}]`, data[e][t]);
                    }
                } else {
                    form.set(e, data[e]);
                }
            }
            // set boolean properties , we conver it becuase in back-end receive it as string ('true' , 'false') , so we skip it by convert to number
            for (const e of booleans) {
                form.set(e, data[e] ? 1 : 0);
            }

            // set files
            for (const e of files) {
                if (typeof data[e] != "string" && data[e] != null) {
                    form.set(e, data[e], data[e].name);
                } else {
                    form.delete(e);
                }
            }
            form.id = data.id;
            return form;
        }
    }
};
</script>
