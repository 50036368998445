export default class BaseStoreAdvancedModule {
    constructor(serviceApi, namespaced = true) {
        this.namespaced = namespaced;

        this.state = {
            items: [],
            item: []
        };

        this.getters = {
            getById: state => id => {
                return state.items.data && state.items.data.find(item => item.id === id);
            }
        };

        this.mutations = {
            ADD(state, item) {
                if (state.items.data) {
                    state.items.data.data.unshift(item);
                    state.items.data.total++;
                    state.items.data.to++;
                } else {
                    state.items = {
                        data: { data: [item] }
                        // meta: { to: 1, total: 1 }
                    };
                }
            },

            UPDATE(state, item) {
                state.items.data.data = [...state.items.data.data.map(e => (e.id !== item.id ? e : item))];
            },
            SET_ITEMS(state, items) {
                state.items = items;
            },
            SET_ITEM(state, item) {
                state.item = item;
            },
            DELETE(state, id) {
                if (state.items.data) {
                    state.items.data.data = state.items.data.data.filter(item => item.id !== id);
                    //state.items.meta.to--;
                    //state.items.meta.total--;
                }
            }
        };

        this.actions = {
            add({ commit }, data) {
                return serviceApi.create(data).then(r => {
                    Toast.success("Added successfully");
                    commit("ADD", r.data.data);
                    return r;
                });
            },

            fetchAll({ commit }, params = {}) {
                return serviceApi.getAll(params).then(r => {
                    commit("SET_ITEMS", r.data);
                });
            },

            fetchById({ commit }, id) {
                return serviceApi.getById(id).then(r => {
                    commit("SET_ITEM", r.data);
                });
            },

            update({ commit }, data) {
                return serviceApi.update(data).then(r => {
                    Toast.success("The update was successful");
                    commit("UPDATE", r.data.data);
                    return r;
                });
            },

            delete({ commit }, id) {
                return serviceApi.delete(id).then(r => {
                    Toast.success("Successfully deleted");
                    commit("DELETE", id);
                    return r;
                });
            }
        };
    }
}
