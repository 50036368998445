<template>
    <base-crud
        :icon="$t('card_types.icon')"
        :title="$t('card_types.title')"
        stateModule="cardType"
        :enable-add-option="true"
        :enable-update-option="true"
        :headers="headers"
        :add-edit-form-width="912"
        :default-valus-for-add="defaultValusForAdd"
    >
        <template #addEditForm="{ item }">
            <v-row>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required|min:3|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.name"
                            :label="$t('attributes.name')"
                            :error-messages="errors"
                            counter
                            :maxlength="32"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.min_count')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.min_count"
                            :label="$t('attributes.min_count')"
                            :error-messages="errors"
                            type="number"
                            counter
                            :maxlength="10"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <validation-provider rules="required" :name="$t('attributes.sell_price')" v-slot="{ errors }">
                        <v-text-field
                            v-model.trim="item.sell_price"
                            :label="$t('attributes.sell_price')"
                            :error-messages="errors"
                            type="number"
                            counter
                            :maxlength="10"
                        ></v-text-field>
                    </validation-provider>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-switch v-model="item.active" :label="$t('attributes.state')"></v-switch>
                </v-col>
                <v-col cols="12">
                    <validation-provider rules="max:1000" :name="$t('attributes.note')" v-slot="{ errors }">
                        <v-textarea
                            v-model.trim="item.note"
                            :label="$t('attributes.note')"
                            :error-messages="errors"
                            counter
                            :maxlength="150"
                            rows="2"
                            auto-grow
                            clearable
                        ></v-textarea>
                    </validation-provider>
                </v-col>
            </v-row>
        </template>

        <template #searchForm>
            <v-row align="center">
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.name" :label="$t('attributes.name')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <v-text-field v-model.trim="search.note" :label="$t('attributes.note')" clearable />
                </v-col>
                <v-col cols="12" sm="6" lg="3">
                    <statuses-field v-model.trim="search.state" :label="$t('attributes.state')" clearable />
                </v-col>
            </v-row>
        </template>
    </base-crud>
</template>

<script>
export default {
    data: function() {
        return {
            search: {},
            headers: [
                {
                    text: this.$t("attributes.name"),
                    value: "name",
                    filter: value => {
                        return filterContains(value, this.search.name);
                    }
                },
                {
                    text: this.$t("attributes.min_count"),
                    value: "min_count"
                },
                {
                    text: this.$t("attributes.sell_price"),
                    value: "sell_price"
                },
                {
                    text: this.$t("attributes.note"),
                    value: "note",
                    filter: value => {
                        return filterContains(value, this.search.note);
                    }
                },
                {
                    text: this.$t("attributes.state"),
                    value: "active",
                    filter: value => _dtFilterStatus(value, this.search.state)
                },
                { text: this.$t("attributes.created_at"), value: "created_at" },
                { text: this.$t("attributes.actions"), value: "actions", width: 40 }
            ],
            defaultValusForAdd: { active: true }
        };
    },

    methods: {
        _hasPermissions(permissions) {
            return _hasPermissions(permissions);
        }
    }
};
</script>
