/**
 * This file to set routes for dashboard
 */

/**
 * Properties for these routes
 */
const meta = {
    requiresAuth: true /* route requires authentication */,
    layout: "dashboard-layout" /* route uses dashboard-layout */
};

const routes = [
    {
        path: "/Home",
        //component: require(`@/views/dashboard/Home.vue`).default,
        component: require(`@/views/dashboard/orders/Index.vue`).default,
        meta,
        alias: "/"
    },
    {
        path: "/profile",
        component: require(`@/views/dashboard/Profile.vue`).default,
        meta
    },
    {
        path: "/cards-inquire",
        component: require(`@/views/dashboard/cards/Inquire.vue`).default,
        meta
    },
    /* {
        path: "/accounts",
        component: require(`@/views/dashboard/accounts/Index.vue`).default,
        meta
    }, */
    /* {
        path: "/home",
        component: require(`@/views/dashboard/ExchangeRates.vue`).default,
        meta: { ...meta, stateModule: "exchangeRate" },
        alias: "/"
    }, */

    {
        path: "/orders",
        component: require(`@/views/dashboard/orders/Index.vue`).default,
        meta
    },
    {
        path: "/orders/:id",
        component: require(`@/views/dashboard/orders/Show.vue`).default,
        meta
    },
    {
        path: "/settlements",
        component: require(`@/views/dashboard/settlements/Index.vue`).default,
        meta
    },
    {
        path: "/settlements/:id",
        component: require(`@/views/dashboard/settlements/Show.vue`).default,
        meta
    },
    {
        path: "/cards-statistics",
        component: require(`@/views/dashboard/cards/Statistics.vue`).default,
        meta
    },
    {
        path: "/card-types",
        component: require(`@/views/dashboard/CardTypes.vue`).default,
        meta: { ...meta, stateModule: "cardType" }
    },
    {
        path: "/imported-cards",
        component: require(`@/views/dashboard/ImportedCards.vue`).default,
        meta: { ...meta, stateModule: "cardType" }
    }
    /* {
        path: "/transactions",
        component: require(`@/views/dashboard/Transactions.vue`).default,
        meta
    },
    {
        path: "/exchanges",
        component: require(`@/views/dashboard/Exchanges.vue`).default,
        meta
    },
    {
        path: "/transfers",
        component: require(`@/views/dashboard/Transfers.vue`).default,
        meta
    },
    {
        path: "/sell-offers",
        component: require(`@/views/dashboard/sell/Index.vue`).default,
        meta
    },
    {
        path: "/sell-offers-my",
        component: require(`@/views/dashboard/sell/My.vue`).default,
        meta
    },
    {
        path: "/sell-offers-purchases",
        component: require(`@/views/dashboard/sell/Purchases.vue`).default,
        meta
    },
    {
        path: "/chat",
        component: require(`@/views/dashboard/chat/Index.vue`).default,
        meta: { ...meta, layoutPadding: "p-0" }
    } */
];

export default routes;
