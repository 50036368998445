var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-flex',[_c('div',{staticClass:"mt-4 mx-auto",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"text-center mb-5 pb-y5"},[_c('v-icon',{attrs:{"size":"175px"}},[_vm._v("mdi-account-tie")]),_c('h3',[_vm._v(_vm._s(_vm.$t("auth.register")))])],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signIn)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","maxlength":32},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|phone","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","maxlength":10},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('attributes.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.email'),"error-messages":errors,"counter":"","maxlength":64},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"label":_vm.$t('attributes.password'),"error-messages":errors,"max-length":32,"show-icon":false},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.password"}})]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"my-4",attrs:{"type":"submit","color":"info","block":""}},[_vm._v(_vm._s(_vm.$t("auth.register")))]),_c('v-btn',{staticClass:"my-4",attrs:{"outlined":"","color":"info","block":"","to":"/login"}},[_vm._v(_vm._s(_vm.$t("auth.login")))])],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }