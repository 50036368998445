<template>
    <v-container class="fill-height" fluid>
        <v-flex>
            <div class="mt-4 mx-auto" style="max-width: 400px;">
                <div class="text-center mb-5 pb-y5">
                    <v-icon size="175px">mdi-account-tie</v-icon>
                    <h3>{{ $t("auth.register") }}</h3>
                </div>

                <validation-observer v-slot="{ handleSubmit }">
                    <v-form class="px-3" @submit.prevent="handleSubmit(signIn)">
                        <!-- show errors if there is errors  -->
                        <has-errors :errors="errors"></has-errors>

                        <v-row>
                            <v-col cols="12">
                                <validation-provider rules="required|min:6|max:32" :name="$t('attributes.name')" v-slot="{ errors }">
                                    <v-text-field
                                        v-model.trim="user.name"
                                        :label="$t('attributes.name')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="32"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12">
                                <validation-provider rules="required|phone" :name="$t('attributes.phone')" v-slot="{ errors }">
                                    <v-text-field
                                        v-model.trim="user.phone"
                                        :label="$t('attributes.phone')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="10"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>
                            <v-col cols="12">
                                <validation-provider rules="required|email" :name="$t('attributes.email')" v-slot="{ errors }">
                                    <v-text-field
                                        v-model.trim="user.email"
                                        :label="$t('attributes.email')"
                                        :error-messages="errors"
                                        counter
                                        :maxlength="64"
                                    ></v-text-field>
                                </validation-provider>
                            </v-col>

                            <v-col cols="12">
                                <validation-provider rules="required|min:6|max:32" :name="$t('attributes.password')" v-slot="{ errors }">
                                    <password-field
                                        v-model.trim="user.password"
                                        :label="$t('attributes.password')"
                                        :error-messages="errors"
                                        :max-length="32"
                                        :show-icon="false"
                                    >
                                    </password-field>
                                </validation-provider>
                            </v-col>

                            <!-- <v-col cols="12">
                                <validation-provider rules="size:10240|ext:pdf" :name="$t('attributes.file')" v-slot="{ errors }">
                                    <v-file-input
                                        v-model="user.file"
                                        :label="$t('attributes.file')"
                                        :error-messages="errors"
                                        show-size
                                        counter
                                    ></v-file-input>
                                </validation-provider>
                            </v-col> -->
                        </v-row>

                        <v-btn type="submit" color="info" class="my-4" block>{{ $t("auth.register") }}</v-btn>
                        <v-btn outlined color="info" class="my-4" block to="/login">{{ $t("auth.login") }}</v-btn>
                    </v-form>
                </validation-observer>
            </div>
        </v-flex>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",

    data: () => ({
        errors: {}, // validation errors for add/update request
        user: {} // to save temp data to use it in add,update and delete
    }),

    methods: {
        ...mapActions("auth", ["register"]),

        /**
         * Add or update data in server depends on isUpdate property
         */
        signIn() {
            this.$store.state.app.loading = true;

            this.errors = {};

            this.register(this.user)
                .then(() => {
                    //if (_hasPermissions("invoices.index")) {
                    //    this.$router.push("/transactions");
                    //} else {
                    this.$router.push("/home");
                    //}
                })
                .catch(r => {
                    window.r = r;
                    this.errors = r.response && r.response.data && r.response.data.errors;
                })
                .finally(() => {
                    this.$store.state.app.loading = false;
                });
        }
    }
};
</script>
