<template>
    <v-autocomplete
        :items="types"
        item-text="name"
        item-value="id"
        :value="value"
        :label="label || $t('attributes.type')"
        @input="$emit('input', $event)"
        :clearable="clearable"
        :outlined="outlined"
        :error-messages="errorMessages"
        :chips="chips"
        :return-object="false"
    ></v-autocomplete>
</template>
<script>
export default {
    name: "TransactionTypesField",

    props: {
        value: [String, Number],
        label: { type: String },
        errorMessages: [String, Array],
        clearable: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        chips: { type: Boolean, default: false }
    },

    data: function() {
        return {
            types: [
                { id: 1, name: "شحن" },
                { id: 2, name: "سحب" },
                { id: 3, name: "تحويل عملة" },
                { id: 4, name: "تحويل" },
                { id: 5, name: "شراء" },
                { id: 6, name: "بيع" },
                { id: 7, name: "حجز بيع" },
                { id: 8, name: "استرجاع حجز بيع" }
            ]
        };
    }
};
</script>
