var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('profile.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("profile.title")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"profileForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfileOfUser)}}},[_c('has-errors',{attrs:{"errors":_vm.errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:32","name":_vm.$t('attributes.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.name'),"error-messages":errors,"counter":"","outlined":"","maxlength":32,"readonly":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('attributes.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.phone'),"error-messages":errors,"counter":"","outlined":"","maxlength":32},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":_vm.$t('attributes.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('attributes.email'),"error-messages":errors,"counter":"","outlined":"","maxlength":64},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.user.created_at,"label":_vm.$t('attributes.created_at'),"outlined":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"value":_vm.user.updated_at,"label":_vm.$t('attributes.updated_at'),"outlined":"","readonly":""}})],1)],1),_c('v-divider')],1)]}}])})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('base-card',{staticClass:"my-4 mx-auto",attrs:{"icon":_vm.$t('auth.update_password.icon')},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("auth.update_password.title")))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-divider'),_c('validation-observer',{ref:"changePasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-5",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePasswordOfUser)}}},[_c('has-errors',{attrs:{"errors":_vm.password_errors}}),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.current_password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"label":_vm.$t('attributes.current_password'),"error-messages":errors,"max-length":32,"outlined":"","show-icon":false},model:{value:(_vm.passowrd.current_password),callback:function ($$v) {_vm.$set(_vm.passowrd, "current_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passowrd.current_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:32","name":_vm.$t('attributes.new_password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password-field',{attrs:{"label":_vm.$t('attributes.new_password'),"error-messages":errors,"max-length":32,"outlined":"","show-icon":false},model:{value:(_vm.passowrd.new_password),callback:function ($$v) {_vm.$set(_vm.passowrd, "new_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passowrd.new_password"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('div',{staticClass:"text-end py-3"},[_c('v-btn',{attrs:{"min-width":"150px","type":"submit"}},[_vm._v(_vm._s(_vm.$t("options.update")))])],1)],1)]}}])})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }