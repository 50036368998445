/**
 * This module for permission
 */
import Axios from "axios";

export default {
    namespaced: true,

    state: {
        items: []
    },

    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        }
    },

    actions: {
        fetch({ commit, state }) {
            if (state.items.length) return;
            return Axios.get("permissions").then(r => {
                commit("SET_ITEMS", r.data.data);
            });
        }
    }
};
