import Axios from "axios";

export default class BaseServiceApi {
    constructor(base_url) {
        this.base_url = base_url;
    }

    /**
     * Display a listing of the items.
     *
     * @param {object} params filter (search) options
     * @returns {Promise}
     */
    getAll(params = {}) {
        return Axios.get(`${this.base_url}`, { params });
    }

    /**
     * Get all items with specific properties of items.
     *
     * @returns {Promise}
     */
    getAllShort() {
        return Axios.get(`${this.base_url}/short`);
    }

    /**
     * Store a new item in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    create(data) {
        return Axios.post(this.base_url, data);
    }

    /**
     * Show data for a given item.
     *
     * @param {int} id
     * @returns {Promise}
     */
    getById(id) {
        return Axios.get(`${this.base_url}/${id}`);
    }

    /**
     * Update the given item in server.
     *
     * @param {object} data item properties
     * @returns {Promise}
     */
    update(data) {
        if (data instanceof FormData) {
            data.set("_method", "PUT");
            return Axios.post(`${this.base_url}/${data.id}`, data);
        }
        return Axios.put(`${this.base_url}/${data.id}`, data);
    }

    /**
     * Remove the specified item from server.
     *
     * @param {int} id
     * @returns {Promise}
     */
    delete(id) {
        return Axios.delete(`${this.base_url}/${id}`);
    }
}
