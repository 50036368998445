import Axios from "axios";

export default {
    login(credentials) {
        return Axios.post("login", credentials);
    },

    register(credentials) {
        return Axios.post("register", credentials);
    },

    profile() {
        return Axios.get("profile");
    },

    updateProfile(user) {
        return Axios.post("update-profile", user);
    },

    updatePassword(user) {
        return Axios.post("update-password", user);
    },

    logout(data) {
        return Axios.post("logout", data);
    }
};
