<template>
    <div>
        <base-card class="my-4 mx-auto" :icon="$t('cards.statistics.icon')">
            <template #title>
                <div class="d-flex justify-space-between">
                    <div class="headline">{{ $t("cards.statistics.title") }}</div>
                </div>
            </template>

            <template #content>
                <v-divider></v-divider>
                <!-- Start show headers options -->
                <div class="px-4">
                    <v-select v-model="selectedHeaders" :items="headers" :label="$t('options.show_columns')" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip filter>
                                <span>{{ item.text }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <!-- End show headers options -->
                <v-divider></v-divider>

                <!-- Start search panel -->
                <v-expansion-panels class="" focusable>
                    <v-expansion-panel>
                        <v-expansion-panel-header class="justify-start">
                            <h5 class="subtitle-1"><v-icon>mdi-magnify</v-icon> {{ $t("options.search") }}</h5>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <!-- Start search options -->
                            <validation-observer ref="searchForm" v-slot="{ handleSubmit }">
                                <v-form class="px-4" @submit.prevent="handleSubmit(filterData)">
                                    <v-row align="center">
                                        <v-col cols="12" sm="6" lg="3" class="pb-0">
                                            <card-types-field v-model.trim="search.card_type_id" clearable />
                                        </v-col>

                                        <v-col cols="12" sm="6" lg="3" class="pb-0" v-for="(field, index) in searchFields" :key="index">
                                            <component
                                                v-if="typeof field === 'object'"
                                                :is="field.component"
                                                v-model.trim="search[field.name]"
                                                :label="field.label"
                                                v-bind="field.props"
                                            />
                                            <v-text-field
                                                v-else
                                                v-model.trim="search[field]"
                                                :label="$t(`attributes.${field}`)"
                                                maxlength="32"
                                                clearable
                                            />
                                        </v-col>
                                        <v-col cols="12" sm="6" lg="auto" class="mb-2">
                                            <v-btn class="me-2" :color="$store.state.app.color" type="submit"><v-icon>mdi-magnify</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                            <!-- End search options -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <!-- End search options -->

                <v-divider></v-divider>

                <v-data-table
                    :headers="selectedHeaders"
                    :items="data.data || []"
                    :items-per-page="100"
                    hide-default-footer
                    class="elevation-1"
                    :item-class="rowClass"
                >
                    <template v-slot:item.actions="{ item }">
                        <div style="width: 40px">
                            <v-btn x-small color="info" class="me-1" fab :to="`/orders/${item.id}`">
                                <v-icon>mdi-information-variant</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>

                <!-- Start pagination -->
                <pagination :data="data || {}" @page-changed="filterData" />
                <!-- End pagination -->
            </template>
        </base-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/index";

/**
 * Bring data from server and save it in vuex(state mangament)
 * I will get data automaticly becuase I linked data to data computed property
 */
function fetchData(routeTo, next, component = null) {
    let page = routeTo.query.page || 1;
    let params = routeTo.query;
    params.page = page;

    axios.get("cards/statistics", { params }).then(r => {
        if (component) {
            component.data = r.data.data;
            next();
        } else {
            next(vm => {
                vm.data = r.data.data;
            });
        }
    });
}

export default {
    /**
     * Before enter to this view bring data from server
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        fetchData(routeTo, next);
    },

    /**
     * Before update(when changing paramaters in url) this view bring data from server
     */
    beforeRouteUpdate(routeTo, routeFrom, next) {
        fetchData(routeTo, next, this);
    },

    data: function() {
        return {
            errors: {},
            search: Object.assign({}, this.$route.query),
            data: {},
            searchFields: [
                //"id",
                //"payment_method",
                { component: "date-field", name: "date_from", label: this.$t("search.date_from"), props: { clearable: true } },
                { component: "date-field", name: "date_to", label: this.$t("search.date_to"), props: { clearable: true } },
                { component: "date-field", name: "sold_at_from", label: this.$t("search.sold_at_from"), props: { clearable: true } },
                { component: "date-field", name: "sold_at_to", label: this.$t("search.sold_at_to"), props: { clearable: true } },
                { component: "date-field", name: "canceled_at_from", label: this.$t("search.canceled_at_from"), props: { clearable: true } },
                { component: "date-field", name: "canceled_at_to", label: this.$t("search.canceled_at_to"), props: { clearable: true } }
            ],
            headers: [
                { text: this.$t("attributes.card_type"), value: "card_type" },
                { text: this.$t("attributes.total"), value: "total" },
                { text: this.$t("attributes.available"), value: "available" },
                { text: this.$t("attributes.sold"), value: "sold" },
                { text: this.$t("attributes.canceled"), value: "canceled" }
            ],
            selectedHeaders: []
        };
    },

    created() {
        this.selectedHeaders = this.headers.filter(i => ![].includes(i.value));
    },

    methods: {
        rowClass(item) {
            return null;
            //return (!item.activated_at && "green white--text") || (!item.active && "red white--text");
        },

        /**
         * Set filter(search) options in url(route)
         * When I set filter options in url , beforeRouteUpdate gurad will be called automatic and it will load data from server
         * @param page is optional paramter to specific which page to load from server (pagination)
         */
        filterData(page = 1) {
            let params = Object.assign({}, this.search);
            this.$router.push({ query: { ...params, page } }).catch(() => {});
        }
    }
};
</script>
