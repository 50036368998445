<template>
    <!-- Start pagination -->
    <div class="text-center px-4">
        <v-row justify="space-between" align="center">
            <v-col cols="auto" class="mb-3 mt-1">
                <!-- show info about pagination (from - to - total ) -->
                {{ $t("pagination.showing_from_to_items", { from, to, total }) }}
                <!--/ end show info about pagiantion -->
            </v-col>
            <v-col cols="auto">
                <v-pagination
                    color="#343F4E"
                    class="my-4"
                    :total-visible="9"
                    :length="lastPage"
                    :value="currentPage"
                    @input="pageChanged"
                ></v-pagination>
            </v-col>
        </v-row>
    </div>
    <!-- End pagination -->
</template>

<script>
export default {
    name: "Pagination",

    props: {
        data: {
            type: Object
        }
    },

    computed: {
        currentPage() {
            return this.data.current_page || 0;
        },
        total() {
            return this.data.total || 0;
        },
        from() {
            return this.data.from || 0;
        },
        to() {
            return this.data.to || 0;
        },
        lastPage() {
            return this.data.last_page || 0;
        }
    },
    methods: {
        pageChanged(page) {
            this.$emit("page-changed", page);
        }
    }
};
</script>
